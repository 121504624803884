import React, { useEffect } from "react"
import styled from "styled-components"

import { navigate } from "gatsby"

import PublicLayout from "../components/PublicLayout"

import {
  FeatureCard,
  ReviewsDiv,
  ReviewsHeading,
  RockReviews,
  GetStartedArr,
} from "../components/LandingBodyHelpers"

const Div2 = styled.div`
  padding: 0 10%;
  margin: 0 auto;
  display: flex;
  flex-flow: wrap;
  justify-content: flex-start; // *** equals the space on left and right -
  margin-top: 40px;
`

const Div3 = styled.div`
  width: 80%;
  margin: 0 auto;
  margin-top: 200px;
`

export const InfoContent = styled.p`
  margin-top: 0;
  margin-bottom: 40px;

  font-size: 14px;
  line-height: 160%;

  color: #3c3c3c;
`

export function InfoBody({ title, children }) {
  return (
    <PublicLayout darkLogo title={title}>
      <Div2>{children}</Div2>
      <div>
        <ReviewsDiv>
          <ReviewsHeading>Reviews</ReviewsHeading>
        </ReviewsDiv>
        <RockReviews></RockReviews>
      </div>
      <Div3>
        <ReviewsHeading>Get started with MountProxies</ReviewsHeading>
      </Div3>
      <Div2>
        {GetStartedArr.map(item => {
          return (
            <FeatureCard
              key={item.key}
              logo={item.logo}
              heading={item.heading}
              para={item.para}
              widthPercentage="30%"
              linkto={item.linkto}
            ></FeatureCard>
          )
        })}
      </Div2>
    </PublicLayout>
  )
}

export default function DesktopLandingBody() {
  useEffect(() => {
    navigate("/")
  }, [])

  return null
}
